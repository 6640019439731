import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Fragment} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AccountVerification from "./pages/AccountVerification";
import PasswordReset from "./pages/PasswordReset";
import {Helmet} from "react-helmet";

function NotFound() {
    return (<Fragment>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <h1>Not Found</h1>
        </Fragment>
    );
}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<NotFound />} />
                <Route path="email-verification" element={<AccountVerification />} >
                    <Route element={<AccountVerification />} />
                    <Route path=":token" element={<AccountVerification />} />
                </Route>
                <Route path="password-reset" element={<PasswordReset />} >
                    <Route element={<PasswordReset />} />
                    <Route path=":token" element={<PasswordReset />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;