import {Fragment, useEffect, useState} from "react";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {useFormFields} from "../lib/hooksLib";
import Form from "react-bootstrap/Form";
import zxcvbn from "zxcvbn";
import {Alert, Button, ProgressBar} from "react-bootstrap";
import AlertDismissible from "../components/AlertDismissible";
import {Helmet} from "react-helmet";

function PasswordReset() {
    const location = useLocation();
    let {token} = useParams();

    if (!token) {
        let search = location.search;
        if (search) {
            search = search.substring(1);
            return (<Navigate to={"./" + search}/>);
        } else {
            return <PasswordResetWrap token="invalid"/>;
        }
    }

    return <PasswordResetWrap token={token}/>
}

function PasswordResetWrap(props) {
    return (
        <Fragment>
            <Helmet>
                <title>{process.env.REACT_APP_WEBSITE_NAME} Password Reset</title>
            </Helmet>
            <PasswordResetToken {...props} />
        </Fragment>
    )
}

function PasswordResetToken(props) {
    const {token} = props;

    const [loading, setLoading] = useState(true);

    const [exist, setExist] = useState(false);
    const [existData, setExistData] = useState({errors: []});

    const [error, setError] = useState(false);
    const [data, setData] = useState({errors: []});

    const [validateForm, setValidateForm] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordChanged, setPasswordChanged] = useState(false);

    const [fields, handleFieldChange] = useFormFields({
        password: "",
        confirmPassword: ""
    });


    useEffect(() => {
        const validated = fields.password.length > 0 && fields.confirmPassword.length > 0;
        setValidateForm(validated);
        if (validated) {
            setPasswordMatch(fields.password === fields.confirmPassword);
        }
    }, [fields])

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/password-reset/" + token)
            .then(response => {
                if (!response.ok) {
                    setExist(true);
                }
                return response.json()
            })
            .then(data => setExistData(data))
            .catch(() => setExist(true))
            .finally(() => setLoading(false))
    }, [token]);


    const testedResult = zxcvbn(fields.password);


    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({password: fields.password, confirmPassword: fields.confirmPassword})
        };

        fetch(process.env.REACT_APP_API_URL + "/password-reset/" + token, requestOptions)
            .then(response => {
                if (response.ok) {
                    setPasswordChanged(true)
                } else {
                    setError(true);
                }
                return response.json()
            })
            .then(data => setData(data))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }

    if (loading) {
        return <h1>Loading...</h1>
    }

    if (passwordChanged) {
        return (
            <div className="text-center">
                    <span className="checkmark ok">
                        <i className="far fa-check-circle"/>
                    </span>
                <h1>Password changed!</h1>
                <p>Your password has been successfully changed for {existData.data.username} </p>
            </div>
        )
    }

    return (
        <div className="container">
            {exist && (<div className="text-center">
                <span className="checkmark bad">
                    <i className="far fa-times-circle"/>
                </span>

                <h1>Something went wrong!</h1>
                <p>{existData.errors}</p>
            </div>)}

            {!exist && (<Fragment>
                <Form action="/" onSubmit={handleSubmit}>

                    <h1>Password reset for {existData.data.username}</h1>
                    {!passwordMatch && <Alert variant={"danger"}>Passwords don't match</Alert>}
                    {error && <AlertDismissible title={"Something went wrong"} body={data.errors} />}

                    <Form.Group className="mb-3">
                        <Form.Label>Password*</Form.Label>
                        <Form.Control type="password" id="password" placeholder="Password"
                                      onChange={handleFieldChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Confirm password*</Form.Label>
                        <Form.Control type="password" id="confirmPassword" placeholder="Password"
                                      onChange={handleFieldChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Score</Form.Label>
                        <ProgressBar now={testedResult.score} max={4}/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        {validateForm && testedResult.feedback.suggestions && testedResult.feedback.suggestions.map((data) => {
                            return <li key={data}>{data}</li>
                        })}
                    </Form.Group>

                    <Button variant="success" type="submit" disabled={ false && (!validateForm || !passwordMatch)} >Change
                        password</Button>
                </Form>
            </Fragment>)}
        </div>
    );
}

export default PasswordReset;