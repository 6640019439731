const {useState} = require("react");
const {Alert} = require("react-bootstrap");

function AlertDismissible(props) {
    const {title, body } = props;
    const [show, setShow] = useState(true);

    let variant = "danger";

    if (props.variant) {
        variant = props.variant;
    }

    if (show) {
        return (
            <Alert variant={variant} onClose={() => setShow(false)} dismissible>
                {title && <Alert.Heading>{title}</Alert.Heading>}
                <p className={"mb-0"}>{body}</p>
            </Alert>
        );
    }
    return null;
}

export default AlertDismissible;