import {Fragment, useEffect, useState} from "react";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

function AccountVerification() {
    const location = useLocation();
    let {token} = useParams();

    if (!token) {
        let search = location.search;
        if (search) {
            search = search.substring(1);
            return (<Navigate to={"./" + search}/>);
        } else {
            return <AccountVerificationTokenWrap token="invalid"/>;
        }
    }

    return <AccountVerificationTokenWrap token={token}/>
}

function AccountVerificationTokenWrap(props) {
    return (
        <Fragment>
            <Helmet>
                <title>{process.env.REACT_APP_WEBSITE_NAME} Email Verification</title>
            </Helmet>
            <AccountVerificationToken {...props} />
        </Fragment>
    )
}

function AccountVerificationToken(props) {
    const {token} = props;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({ errors: [] });

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/account-verification/" + token)
            .then(response => {
                if (!response.ok) {
                    setError(true);
                }
                return response.json()
            })
            .then(data => setData(data))
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, [token]);

    if (loading) {
        return <h1>Loading...</h1>
    }

    return (
        <div className="container">
            {error && (<div className="text-center">
                <span className="checkmark bad">
                    <i className="far fa-times-circle"/>
                </span>

                <h1>Something went wrong!</h1>
                <p>{data.errors}</p>
            </div>)}

            {!error && (<div className="text-center">
                <span className="checkmark ok">
                    <i className="far fa-check-circle"/>
                </span>

                <h1>Email verified!</h1>
                <p>Your email address {data.data.email} has been verified and linked to {data.data.realname}!</p>
            </div>)}
        </div>
    );
}

export default AccountVerification;